// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/database'; // If you're using Firebase Realtime Database

const firebaseConfig = {
    apiKey: "AIzaSyCybIPW1XKn7c8sSQlBOicCxaq4d1q5RYY",
    authDomain: "contactform-13912.firebaseapp.com",
    databaseURL: "https://contactform-13912-default-rtdb.firebaseio.com",
    projectId: "contactform-13912",
    storageBucket: "contactform-13912.appspot.com",
    messagingSenderId: "911233471947",
    appId: "1:911233471947:web:d20ba168bc53197b3cd24e"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
