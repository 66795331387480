import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // Add your email handling logic here

    navigate('/emailsubscription'); // Redirect to the email subscription page
  };


  return (
    <section id="contact" className="footer_wrapper mt-3 mt-md-0 pb-0">
        <div className="container pb-3">
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <h5>Comp Assist Pro</h5>
                    <p className="ps-0">IT assistance services provide businesses with technical expertise, ensuring seamless operations, troubleshooting issues, maintaining infrastructure, and implementing the latest technological solutions for optimal performance and security.</p>
                    
                </div>
                <div className="col-lg-3 col-md-6">
                    <h5>Important Links</h5>
                    <ul className="link-widget p-0">
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/privacy-policy">Privacy & Policy</Link></li>
                        <li><Link to="/terms-condition">Terms & Condition</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h5>Contact Details</h5>
                    <div className="contact-info">
                        <ul className="list-unstyled">
                            {/* Replace "#" with actual links if needed */}
                            <li><a href="#" className="text-white"><i className="fa fa-home me-3 text-white"></i> No6401 31ST ST S APT 112 SAINT PETERSBURG, FL. 33712 US</a></li>
                            <li><a href="#" className="text-white"><i className="fa fa-home me-3 text-white"></i> 2296 Henderson Mill Rd #116, Atlanta, GA 30345</a></li>
                            <li><a href="#" className="text-white"><i className="fa fa-phone me-3 text-white"></i>+1 (877) 313-4306</a></li>
                            <li><a href="#" className="text-white"><i className="fa fa-envelope me-3 text-white"></i>support@compassistpro.com</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
      <h5>Newsletter</h5>
      <form onSubmit={handleEmailSubmit}>
        <div className="form-group mb-4">
          <input type="email" className="form-control bg-transparent white-font-input" placeholder="enter your email here" required />
          <button type="submit" className="main-btn rounded-2 mt-3 border-white text-white">Subscribe</button>
        </div>
      </form>
    </div>
            </div>
        </div>
        <div className="container-fluid copyright-section">
            <p>Copyright <Link to="/">Â© Comp Assist Pro.</Link> All Rights Reserved</p>
        </div>
    </section>
  );
}

export default Footer;
