import React from 'react'
import Partner1 from '../images/partners/500startups.png'
import Partner2 from '../images/partners/bbb-logo.png'
import Partner3 from '../images/partners/mcafee.png'
import Partner4 from '../images/partners/ms-partner.png'
import Partner5 from '../images/partners/trust-pilot.png'

const Swipper = () => {
  return (
    <>
    <section id="blog" className="blog_wrapper pb-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 section-title text-center">
              <h3>We Featured on!</h3>
            </div>
          </div>
        </div>
        <div className="our-partner-slider mt-2">
          <div className="container swiper our-partner">
            <div id="threeItemCarousel" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="d-flex justify-content-around align-items-center flex-wrap">
                    <img src={Partner1} className="partner-image" alt="Partner 1"/>
                    <img src={Partner2} className="partner-image" alt="Partner 2"/>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="d-flex justify-content-around align-items-center flex-wrap">
                    <img src={Partner3} className="partner-image" alt="Partner 3"/>
                    <img src={Partner4} className="partner-image" alt="Partner 4"/>
                  </div>
                </div>
                {/* Add more carousel items if needed */}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#threeItemCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#threeItemCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Swipper