import React from 'react'
import { useNavigate } from 'react-router-dom';
import firebase from '../components/firebaseConfig'; 

const Form = () => {

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, phone } = e.target.elements;
    
        // Add data to Firebase
        firebase.database().ref('contacts').push({
          name: name.value,
          email: email.value,
          phone: phone.value
        })
        .then(() => {
          navigate('/thankyou'); // Redirect after submission
        })
        .catch(error => {
          // Handle errors here
          console.error("Error writing document: ", error);
        });
      };




  return (
    <section id="about" class="about_wrapper">
    <div class="container">
        <div class="row flex-lg-row flex-column-reverse">
            <div class="col-lg-6 mb-4 mb-lg-0 ps-lg-4 text-center">
                {/* <!-- Form design here --> */}
                <form className="contact-form" onSubmit={handleSubmit}>
  <h2 className="form-heading">Get Unlimited IT Assistance</h2>
  <div className="form-group name-icon">
    <input type="text" className="form-control" placeholder="Name" name="name" required />
  </div>
  <div className="form-group email-icon">
    <input type="email" className="form-control" placeholder="Email" name="email" required />
  </div>
  <div className="form-group phone-icon">
    <input type="tel" className="form-control" placeholder="Phone Number" name="phone" required />
  </div>
  <button type="submit" className="main-btn mt-4">Submit</button>
</form>

            </div>
            <div class="col-lg-6 text-center text-lg-start">
                <h3>With <span>COMP ASSIST PRO <br class="d-none d-lg-block" /></span> by your side, experience the assurance of:</h3>
                <p className='fw-bold'>Preventative IT Maintenance</p>
                <li>Our vigilant Comp Care Takers Assistance team doesn't just wait for issues to arise; we proactively monitor and resolve potential IT hiccups, ensuring your business operations run smoothly without interruption.</li>
                <p className='fw-bold'>Access to Expertise</p>
                <li>Whenever you need it, our team of experienced IT experts is on standby. Quick to respond and adept at tackling technological hurdles, we're here to deliver the Assistance you need promptly and efficiently.</li>
                <p className='fw-bold'>Custom-Fitted IT Planning</p>
                <li>Recognizing the distinct needs of each enterprise, Comp Care Takers Assistance devises personalized IT strategies that dovetail with your company's aspirations and expansion plans.</li>
                <p className='fw-bold'>Innovative Technological Edge</p>
                <li>Equip your business with the forefront of technology. Our commitment is to arm our partners with the most advanced tools and innovations, keeping you a step ahead in the competitive marketplace.
</li>
                
               
            </div>
        </div>
    </div>
</section>
  )
}

export default Form