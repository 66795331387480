import React, { useEffect } from 'react';
import backgroundImage from './images/slider/slider3.png'
import Support1 from './images/room/service1.png'
import Service2 from './images/room/service2.png'
import Service3 from './images/room/service3.png'
import Service4 from './images/room/service4.png'
import Service5 from './images/room/service5.png'
import Service6 from './images/room/service6.png'
import Logo1 from './images/services/logo1.png'
import Support7 from './images/services/service7.png'
import Team1 from './images/team/team1.webp'
import Team2 from './images/team/team2.png'
import Team3 from './images/team/team3.png'
import Team4 from './images/team/team4.png'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Form from './components/Form';
import Partner1 from './images/partners/500startups.png'
import Partner2 from './images/partners/bbb-logo.png'
import Partner3 from './images/partners/mcafee.png'
import Partner4 from './images/partners/ms-partner.png'
import Partner5 from './images/partners/trust-pilot.png'
import Swipper from './components/Swipper';
import Gallery1 from './images/gallery/11.png'
import Gallery2 from './images/gallery/22.png'
import Gallery3 from './images/gallery/33.png'
import Gallery4 from './images/gallery/11.png'
import Gallery5 from './images/gallery/55.png'
import Gallery6 from './images/gallery/66.png'
import Review1 from './images/review1.png'
import Review2 from './images/review2.png'
import Whyee1 from './images/services/service8.png'
import Whyee2 from './images/services/service9.png'
import Whyee3 from './images/services/service10.png'
import Whyee4 from './images/services/service11.png'
import MobileStickyButton from './components/MobileStickyButton';
import CallLogo from './components/CallLogo';



const Home = () => {


    const handleCallClick = () => {
        window.dataLayer.push({
          event: 'callClick',
          // You can add additional data here if needed
        });
      };

    const bannerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust the height as needed
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };

    useEffect(() => {
    // Header Scroll
    const nav = document.querySelector('.navbar');
    const handleScroll = () => {
      if (window.scrollY > 50) {
        nav.classList.add('header-scrolled');
      } else {
        nav.classList.remove('header-scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Nav hide
    const navBar = document.querySelectorAll('.nav-link');
    const navCollapse = document.querySelector('.navbar-collapse.collapse');

    navBar.forEach((a) => {
      a.addEventListener('click', () => {
        navCollapse.classList.remove('show');
      });
    });

    // Counters
    const counters = [
      { id: 'count1', start: 0, end: 1287, duration: 3000 },
      { id: 'count2', start: 100, end: 5786, duration: 2500 },
      { id: 'count3', start: 0, end: 1440, duration: 3000 },
      { id: 'count4', start: 0, end: 7110, duration: 3000 },
    ];

    counters.forEach(({ id, start, end, duration }) => {
      const obj = document.getElementById(id);
      if (obj) {
        let current = start;
        const range = end - start;
        const increment = end > start ? 1 : -1;
        const step = Math.abs(Math.floor(duration / range));
        const timer = setInterval(() => {
          current += increment;
          obj.textContent = current;
          if (current === end) {
            clearInterval(timer);
          }
        }, step);
      }
    });

    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
      navBar.forEach((a) => {
        a.removeEventListener('click', () => {
          navCollapse.classList.remove('show');
        });
      });
    };
  }, []);

    


  return (
    <>
    <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="100">
    {/* <!-- Navbar section --> */}
    <Navbar />
    {/* <!-- Navbar section exit -->

    <!-- Banner section --> */}
    <section id="home" className="banner_wrapper p-0" style={bannerStyle}>
            <div className="slide-caption text-center">
                <h1>Instant PC & Laptop Assist 24/7 - Help Anytime, Anywhere!</h1>
                <a className="main-btnsss " href="tel:+18773134306" onClick={() => handleCallClick()}>24X7 Tollfree Assistance <span className='d-none'>+18773134306</span></a>
            </div> 
        </section>
    {/* <!-- Banner section exit -->

    <!-- Form section --> */}
    <Form />

    {/* <!-- Form section exit -->

    <!-- About section --> */}
    <section id="about" class="about_wrapper">
    <div class="container">
        <div class="row flex-lg-row flex-column-reverse ">
            <div class="col-lg-6 text-center text-lg-start">
                <h3>Our <span>Satisfied <br class="d-none d-lg-block" /></span> Clients Speak for Us</h3>
                <p>JOHN CARTER</p>
                <p>Comp Assist Pro provided us with top-notch IT assistance services that perfectly aligned with our business needs. Their professional team of experts is highly responsive and proficient.</p>
                
            </div>
            <div class="col-lg-6 mb-4 mb-lg-0 ps-lg-4 text-center">
                {/* <!-- Rating design here --> */}
                <div class="rating-block">
                    <div class="rating-number">
                        <h2>4.8</h2>
                        <div class="stars yellow-stars">
                            {/* <!-- Assuming 5 stars, with 4 full and one half to represent 4.8 --> */}
                            ★★★★☆
                        </div>
                        <p className='fw-bold'>2,394 Ratings</p>
                        <p className='fw-bold'>Clients Reviews</p>
                    </div>
                    <div class="rating-number">
                        <h2>A+</h2>
                        <div class="stars yellow-stars">
                            {/* <!-- Assuming 5 stars, full for A+ rating --> */}
                            ★★★★★
                        </div>
                        <p className='fw-bold'>125 Reviews</p>
                        <p className='fw-bold'>BBB Rating</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

    {/* <!-- About section exit -->


<!-- Contact section --> */}
    <section id="services" class="services_wrapper">
        <div class="counters mt-5 bg-dark">
            <div class="container">
                <div class="row text-center ">
                <div class="col-sm-12 text-center mb-5">
                    
                    <h3 className='hh'>COMP ASSIST PRO Assistance FOR ALL YOUR NEED !</h3>
                    <a className="main-btnsss " href="tel:+18773134306" onClick={() => handleCallClick()}>24X7 Tollfree Assistance <span className='d-none'>+18773134306</span></a>
                </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Contact section Exit -->

    <!-- Rooms section --> */}
    <section id="rooms" class="rooms_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 section-title text-center mb-5">
                    <h6>Our Expertise</h6>
                    <h3>COMP ASSIST PRO Assistance FOR ALL YOUR NEED !</h3>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-md-4 mb-4 mb-lg-0">
                    <div class="room-item">
                        <img decoding="async" src={Support1} class="img-fluid" />
                        <div class="room-item-wrap">
                            <div class="room-content">
                                <h5 class="text-white mb-lg-5 text-decoration-underline">Laptop & Computer Assist</h5>
                                <p class="text-white"></p>
                                <p class="text-white fw-bold mt-lg-4"></p>
                                {/* <a class="main-btn border-white text-white mt-lg-5" href="tel:+18773134306" onClick={() => handleCallClick()}>Click to Call <span className='d-none'>+18773134306</span></a> */}
                            </div>
                        </div> 
                    </div> 
                </div>
                <div class="col-md-4 mb-4 mb-lg-0">
                    <div class="room-item">
                        <img decoding="async" src={Service2} class="img-fluid" />
                        <div class="room-item-wrap">
                            <div class="room-content">
                                <h5 class="text-white mb-lg-5 text-decoration-underline">PC & MAC Assist</h5>
                                <p class="text-white"></p>
                                <p class="text-white fw-bold mt-lg-4"></p>
                                {/* <a class="main-btn border-white text-white mt-lg-5" href="tel:+18773134306" onClick={() => handleCallClick()}>Click to Call <span className='d-none'>+18773134306</span></a> */}
                            </div>
                        </div>
                    </div> 
                </div>
                <div class="col-md-4 mb-4 mb-lg-0">
                    <div class="room-item">
                        <img decoding="async" src={Service3} class="img-fluid" />
                        <div class="room-item-wrap">
                            <div class="room-content">
                                <h5 class="text-white mb-lg-5 text-decoration-underline">Tablet Assist</h5>
                                <p class="text-white"></p>
                                <p class="text-white fw-bold mt-lg-4"></p>
                                {/* <a class="main-btn border-white text-white mt-lg-5" href="tel:+18773134306" onClick={() => handleCallClick()}>Click to Call <span className='d-none'>+18773134306</span></a> */}
                            </div>
                        </div>
                    </div> 
                </div>
                
            </div>

            <div class="row mt-5">
                <div class="col-md-4 mb-4 mb-lg-0">
                    <div class="room-item">
                        <img decoding="async" src={Service4} class="img-fluid" />
                        <div class="room-item-wrap">
                            <div class="room-content">
                                <h5 class="text-white mb-lg-5 text-decoration-underline">IOS & Android Assist</h5>
                                <p class="text-white"></p>
                                <p class="text-white fw-bold mt-lg-4"></p>
                                {/* <a class="main-btn border-white text-white mt-lg-5" href="tel:+18773134306" onClick={() => handleCallClick()}>Click to Call <span className='d-none'>+18773134306</span></a> */}
                            </div>
                        </div> 
                    </div> 
                </div>
                <div class="col-md-4 mb-4 mb-lg-0">
                    <div class="room-item">
                        <img decoding="async" src={Service5} class="img-fluid" />
                        <div class="room-item-wrap">
                            <div class="room-content">
                                <h5 class="text-white mb-lg-5 text-decoration-underline">Network Setup Assist</h5>
                                <p class="text-white"></p>
                                <p class="text-white fw-bold mt-lg-4"></p>
                                <a class="main-btn border-white text-white mt-lg-5" href="tel:+18773134306">Click to Call <span className='d-none'>+18773134306</span></a>
                            </div>
                        </div>
                    </div> 
                </div>
                <div class="col-md-4 mb-4 mb-lg-0">
                    <div class="room-item">
                        <img decoding="async" src={Service6} class="img-fluid" />
                        <div class="room-item-wrap">
                            <div class="room-content">
                                <h5 class="text-white mb-lg-5 text-decoration-underline">Printer Assist</h5>
                                <p class="text-white"></p>
                                <p class="text-white fw-bold mt-lg-4"></p>
                                {/* <a class="main-btn border-white text-white mt-lg-5" href="tel:+18773134306" onClick={() => handleCallClick()}>Click to Call <span className='d-none'>+18773134306</span></a> */}
                            </div>
                        </div>
                    </div> 
                </div>
                
            </div>
        </div>
    </section>
    {/* <!-- Rooms Section Exit -->

    <!-- Contact section --> */}
    <section id="services" class="services_wrapper">
        <div class="counters mt-5 bg-dark">
            <div class="container">
                <div class="row text-center ">
                <div class="col-sm-12 text-center mb-5">
                    
                    <h3 className='hh'>COMP ASSIST PRO Assistance FOR ALL YOUR NEED !</h3>
                    <a className="main-btnsss imp" href="tel:+18773134306" onClick={() => handleCallClick()}>24X7 Tollfree Assistance <span className='d-none'>+18773134306</span></a>
                </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Contact section Exit -->

    <!-- Services section --> */}
    <section id="services" class="services_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 section-title text-center mb-5">
                    <h6>Features & Benefits</h6>
                    <h3>Why Choose Us ?</h3>
                </div>
            </div>
            <div class="row align-items-center service-item-wrap">
                <div class="col-lg-7 p-lg-0">
                    {/* <!--Service Area Start--> */}
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade active show" id="spa" role="tabpanel">
                            <img decoding="async" src={Whyee1}  alt={Whyee4} />
                        </div>
                        <div class="tab-pane fade" id="restaurent" role="tabpanel">
                            <img decoding="async" src={Whyee2} alt="" />
                        </div>
                        <div class="tab-pane fade" id="swimming" role="tabpanel">
                            <img decoding="async" src={Whyee3} alt="" />
                        </div>
                        <div class="tab-pane fade" id="conference" role="tabpanel">
                            <img decoding="async" src={Whyee4} alt="" />
                        </div>
                    </div>
                    {/* <!--Service Area End--> */}
                </div>
                <div class="col-lg-5 position-relative">
                    {/* <!--Service Tab Menu Area Start--> */}
                    <div class="service-menu-area">
                        <ul class="nav">
                            <li>
                                <a data-bs-toggle="tab" href="#spa" class="active">
                                    <span class="service-icon">
                                        <img decoding="async"  alt="" />
                                    </span>
                                    <h5>Your Personalized IT Assistance Team:</h5>
                                    <p><span>Dedicated and skilled IT experts </span> focused exclusively on your company, guaranteeing smooth operational flow, bespoke solutions, and quick resolution of tech issues. </p>
                                </a>
                            </li>
                            <li>
                                <a data-bs-toggle="tab" href="#restaurent">
                                    <span class="service-icon">
                                        <img decoding="async" src="./images/services/service-icon2.webp" alt="" />
                                    </span>
                                    <h5>Leading-Edge Tech Solutions:</h5>
                                    <p><span>Utilizing the most recent advancements in technology</span> to provide innovative, future-ready solutions that enhance efficiency and establish new benchmarks for upcoming industry challenges.</p>
                                </a>
                            </li>
                            <li>
                                <a data-bs-toggle="tab" href="#swimming">
                                    <span class="service-icon">
                                        <img decoding="async" src="./images/services/service-icon3.webp" alt="" />
                                    </span>
                                    <h5>Automated Service Excellence:</h5>
                                    <p><span>Efficient and streamlined procedures </span> enabled by smart software, enhancing operational effectiveness, minimizing the need for manual tasks, and delivering consistent, top-quality results.</p>
                                </a>
                            </li>
                            <li>
                                <a data-bs-toggle="tab" href="#conference">
                                    <span class="service-icon">
                                        <img decoding="async" src="./images/services/service-icon4.webp" alt="" />
                                    </span>
                                    <h5>Swift Remote Assistance:</h5>
                                    <p><span>Immediate, remote assistance</span> ensuring quick problem-solving, reducing downtime, and maintaining optimal performance with just a simple click. </p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <!--Service Tab Menu Area End--> */}
                </div>
            </div>
        </div>
        <div class="counter mt-5 bg-dark">
            <div class="container">
                <div class="row text-center ">
                    <div class="col-md-3 mb-lg-0 mb-md-0 mb-5">
                        <h1>
                            <span id="count1"></span>+
                        </h1>
                        <p>Ongoing Projects</p>
                    </div>
                    <div class="col-md-3 mb-lg-0 mb-md-0 mb-5">
                        <h1>
                            <span id="count2"></span>+
                            </h1>
                            <p>Happy Clients</p>
                    </div>
                    <div class="col-md-3 mb-lg-0 mb-md-0 mb-5">
                        <h1>
                            <span id="count3"></span>+
                            </h1>
                            <p>Five Start Ratings</p>
                    </div>
                    <div class="col-md-3 mb-lg-0 mb-md-0 mb-5">
                        <h1>
                            <span id="count4"></span>+
                            </h1>
                            <p>Surved Worldwide</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Services section Exit -->

    <!-- Team section --> */}
    <section id="team" class="team_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 section-title text-center mb-5">
                    <h6>What I can do for you</h6>
                    <h3>Our Special Staff</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card p-0 rounded-3">
                        <img decoding="async" src={Team1} class="img-fluid rounded-3" />
                        <div class="team-info">
                            <h5>Shirley Gibson</h5>
                            <p>Chef Operating Officer</p>
                            <br></br>
                            <ul class="social-network">
                                {/* <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card p-0 rounded-3">
                        <img decoding="async" src={Team2} class="img-fluid rounded-3" />
                        <div class="team-info">
                            <h5>Ashley Sanchez</h5>
                            <p>Chief Customer Manager</p>
                            <br></br>
                            <ul class="social-network">
                                {/* <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card p-0 rounded-3">
                        <img decoding="async" src={Team4} class="img-fluid rounded-3" />
                        <div class="team-info">
                            <h5>Jessica Watson</h5>
                            <p>Chief Operating Officer</p>
                            <br></br>
                            <ul class="social-network">
                                {/* <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card p-0 rounded-3">
                        <img decoding="async" src={Team3} class="img-fluid rounded-3" />
                        <div class="team-info">
                            <h5>Larry Smith</h5>
                            <p>Chief Tecnical Officer</p>
                            <br></br>
                            <ul class="social-network">
                                {/* <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Team Section Exit  -->

    <!-- Gallery section --> */}
    <section id="gallery" class="gallery_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 section-title text-center mb-5">
                    <h6>Best Pictures Of Our Hotel</h6>
                    <h3>Our Gallery</h3>
                </div>
            </div>
            <div class="row g-0">
                <div class="col-lg-3 col-md-6 gallery-item">
                    <img decoding="async" src={Gallery1} class="img-fluid w-100" />
                    <div class="gallery-item-content"></div>
                </div>
                <div class="col-lg-3 col-md-6 gallery-item">
                    <img decoding="async" src={Gallery2} class="img-fluid w-100" />
                    <div class="gallery-item-content"></div>
                </div>
                <div class="col-lg-3 col-md-6 gallery-item">
                    <img decoding="async" src={Gallery3} class="img-fluid w-100" />
                    <div class="gallery-item-content"></div>
                </div>
                <div class="col-lg-3 col-md-6 gallery-item">
                    <img decoding="async" src={Gallery1} class="img-fluid w-100" />
                    <div class="gallery-item-content"></div>
                </div>
                <div class="col-md-6 gallery-item">
                    <img decoding="async" src={Gallery5} class="img-fluid w-100" />
                    <div class="gallery-item-content"> </div>
                </div>
                <div class="col-md-6 gallery-item">
                    <img decoding="async" src={Gallery6} class="img-fluid w-100" />
                    <div class="gallery-item-content"> </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Gallery Section Exit -->

    <!-- Pricing section --> */}
    <section id="price" class="price_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 section-title text-center mb-5">
                    <h6>Best & Affordable Price for you</h6>
                    <h3>Our Pricing for Complete Solution</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card p-4 text-center rounded-3">
                        <h5 class="text-decoration-underline mb-4">Starter</h5>
                        <ul class="list-unstyled">
                            <li>
                                <p>Help Desk assistance: Unlimited phone, chat, and email assistance Device performance and speed tuneups Software installation and assistance.</p>
                            </li>
                            <li>
                                <p>Preventative Tune-Ups: Adware/spyware clean up Virus protection.</p>
                            </li>
                            <li>
                                <p>And more assistance..</p>
                            </li>
                            
                        </ul>
                        <hr />
                        <h3>$19<sub class="fs-6 fw-normal">/Month</sub></h3>
                        <a href="tel:+18773134306" onClick={() => handleCallClick()} class="main-btn">Click to Call <span className='d-none'>+18773134306</span></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card p-4 text-center rounded-3">
                        <h5 class="text-decoration-underline mb-4">Basic</h5>
                        <ul class="list-unstyled">
                            
                            <li>
                                <p>All starter features</p>
                            </li>
                            <li>
                                <p>Custom Software Platform: Security Suite – antivirus, VPN, firewall, and more Automatic Backup Employee Monitoring</p>
                            </li>
                            <li>
                                <p>Remote Workforce Assistance: New employee onboardingTracking & computer lockdown at terminationHome network and computer security.</p>
                            </li>
                        </ul>
                        <hr />
                        <h3>$29<sub class="fs-6 fw-normal">/Month</sub></h3>
                        <a href="tel:+18773134306" onClick={() => handleCallClick()} class="main-btn">Click to Call <span className='d-none'>+18773134306</span></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card p-4 text-center rounded-3">
                        <h5 class="text-decoration-underline mb-4">Professional</h5>
                        <ul class="list-unstyled">
                            <li>
                                <p>All Basic features</p>
                            </li>
                            <li>
                                <p>Custom Software Platform: Security Suite – antivirus, VPN, firewall, and more Automatic Backup Employee Monitoring</p>
                            </li>
                            <li>
                                <p>Remote Workforce Assistance: New employee onboardingTracking & computer lockdown at terminationHome network and computer security.</p>
                            </li>
                            <li>
                                <p>Backups & Disaster: RecoveryRemote data migration. Disaster recovery (RTO & RPO).</p>
                            </li>
                        </ul>
                        <hr />
                        <h3>$39<sub class="fs-6 fw-normal">/Month</sub></h3>
                        <a href="tel:+18773134306" onClick={() => handleCallClick()} class="main-btn">Click to Call <span className='d-none'>+18773134306</span></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                    <div class="card p-4 text-center rounded-3">
                        <h5 class="text-decoration-underline mb-4">Enterprise</h5>
                        <ul class="list-unstyled">
                            <li>
                                <p>All Basic features</p>
                            </li>
                            <li>
                                <p>Custom Software Platform: Security Suite – antivirus, VPN, firewall, and more Automatic Backup Employee Monitoring</p>
                            </li>
                            <li>
                                <p>Remote Workforce Assistance: New employee onboardingTracking & computer lockdown at terminationHome network and computer security.</p>
                            </li>
                            <li>
                                <p>Backups & Disaster: RecoveryRemote data migration. Disaster recovery (RTO & RPO).</p>
                            </li>
                            <li>
                                <p>Dedicated IT Manager:.</p>
                            </li>
                        </ul>
                        <hr />
                        <h3>$49<sub class="fs-6 fw-normal">/Month</sub></h3>
                        <a href="tel:+18773134306" onClick={() => handleCallClick()} class="main-btn">Click to Call <span className='d-none'>+18773134306</span></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Pricing Section Exit  -->

    {/* <!-- Testimonial Start  --> */}
    <section id="testimonials" class="testimonials_wrapper pb-0">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 section-title text-center mb-5">
        <h6>Client Testimonials</h6>
        <h3>Hear What Our Clients Have to Say About Us</h3>
      </div>
    </div>
    <div class="row">
      {/* Testimonial 1 */}
      <div class="col-md-6 mb-4">
        <div class="testimonial p-4">
          <div class="testimonial-rating">
            ★★★★★
          </div>
          <p class="testimonial-text">Comp Assist Pro's infrastructure maintenance services have ensured the smooth operation of our business. Their proactive approach and attention to detail give us peace of mind.</p>
          <div class="testimonial-author">
            <img src={Review1} alt="Sarah Thompson" />
            <div class="author-info">
              <strong>Sarah Thompson</strong>
              <span>CEO, ITO</span>
            </div>
          </div>
        </div>
      </div>
      
      {/* Testimonial 2 */}
      <div class="col-md-6 mb-4">
        <div class="testimonial p-4">
          <div class="testimonial-rating">
            ★★★★★
          </div>
          <p class="testimonial-text">Comp Assist Pro has been a reliable partner in ensuring the seamless operation of our business. Their technical assistance, infrastructure maintenance, and security solutions are second to none.</p>
          <div class="testimonial-author">
            <img src={Review2} alt="David Wilson" />
            <div class="author-info">
              <strong>David Wilson</strong>
              <span>CTO, Amagi</span>
            </div>
          </div>
        </div>
      </div>
      
      {/* Add more testimonials as needed */}
    </div>
  </div>
</section>
{/*<!-- Testinomial End --> */}

<Swipper />
    {/*<!-- Footer section --> */}
    <Footer />
    {/* <!-- Footer section exit --> */}
    <CallLogo />
    </body>
    </>
  )
}

export default Home