import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import backgroundImage from '../images/slider/slider6.png'

const Thankyou = () => {

    const bannerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust the height as needed
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };


  return (
    <>
    <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="100">>
    <Navbar />
<section id="home" className="banner_wrapper p-0" style={bannerStyle}>
            {/* <div className="slide-caption text-center">
                <h1>Effective IT Support Services for Your Business</h1>
                <p>Comp Assist Pro is a top-tier IT support services provider that ensures optimal business performance, cybersecurity, and seamless operations.</p>
            </div> */}
        </section>
    
        <section id="services" class="services_wrapper">
        <div class="counters mt-5 bg-dark">
            <div class="container">
                <div class="row text-center ">
                <div class="col-sm-12 text-center mb-5">
                <h2 className='hh'>Thankyou for filling up the form, we will get back to you!</h2>
                    <h3 className='hh mt-5'>COMP ASSIST PRO Assistance FOR ALL YOUR NEED !</h3>
                    <a className="main-btnsss imp" href="tel:+18773134306">Toll Free Click to Call</a>
                </div>
                </div>
            </div>
        </div>
    </section>
    

    <Footer />
    </body>
    </>
  )
}

export default Thankyou