import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Thankyou from './pages/Thankyou';
import Notification from './pages/Notification';

import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

history.listen((location) => {
  window.dataLayer.push({
    event: 'pageview',
    page: location.pathname + location.search,
  });
});

function App() {
  return (
    <Router history={history}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/terms-condition" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/emailsubscription" element={<Notification />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
