import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import backgroundImage from '../images/slider/slider4.png'
import Form from '../components/Form'

const About = () => {

    const bannerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust the height as needed
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };


  return (
    <>
    <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="100">>
    <Navbar />
<section id="home" className="banner_wrapper p-0" style={bannerStyle}>
            {/* <div className="slide-caption text-center">
                <h1>Effective IT Support Services for Your Business</h1>
                <p>Comp Assist Pro is a top-tier IT support services provider that ensures optimal business performance, cybersecurity, and seamless operations.</p>
            </div> */}
        </section>
    <Form />


    <Footer />
    </body>
    </>
  )
}

export default About