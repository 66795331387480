import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Privacy = () => {
  return (
    <>
    <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="100">
    <Navbar />
    <section id="home" className="banner_wrapper p-0">
            <div className="">
                <h2 className='mt-5'>Privacy Policy for Comp Assist Pro</h2>
                <p>At Comp Assist Pro, accessible from comp assist pro, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Comp Assist Pro and how we use it.</p>
                <p>1. Information We Collect</p>
                <li>We collect personal information that you provide to us when you register on our website, express an interest in obtaining information about us or our products and services, when you participate in activities on our website or otherwise when you contact us.</li>
                <li>The personal information that we collect depends on the context of your interactions with us and the website, the choices you make and the products and features you use. The personal information we collect may include the following:</li>
                <li>Name, Phone number, Email</li>
                <p>2. Use of Your Personal Information</p>
                <li>Comp Assist Pro uses the collected data for various purposes:</li>
                <li>To provide and maintain our Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To provide customer assistance</li>
                <li>To gather analysis or valuable information so that we can improve our Service</li>
                <li>To monitor the usage of our Service</li>
      
                <p>9. Contact Information</p>
                <li>For any questions about these Terms and Conditions, please contact us at support@compassistpro.com</li>
                
            </div>
        </section>
    <Footer />
    </body>
    </>
  )
}

export default Privacy