import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Terms = () => {
  return (
    <>
    <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="100">
    <Navbar />
    <section id="home" className="banner_wrapper p-0">
            <div className="">
                <h2 className='mt-5'>Terms and Conditions for Computer Assistance Pro</h2>
                <p>1. Introduction</p>
                <li>Welcome to Computer Assistance Pro. By accessing our website and using our services, you agree to be bound by the following terms and conditions. If you do not agree with any part of these terms, please do not use our website or services.</li>
                <p>2. Services Description</p>
                <li>Computer Assistance Pro provides IT support, computer repair, software installation, etc.</li>
                <p>3. User Obligations</p>
                <li>As a user of our website, you agree to: (a) Provide accurate and current information when requested. (b) Use our services only for lawful purposes. (c) Not to engage in any conduct that disrupts, damages, or impairs our services.</li>
                <p>4. Intellectual Property Rights</p>
                <li>All content on this website, including text, graphics, logos, and images, is the property of Computer Assistance Pro or its content suppliers and is protected by intellectual property laws.</li>
                <p>5. Limitation of Liability</p>
                <li>Computer Assistance Pro will not be liable for any damages that arise from the use of, or the inability to use, the services or content provided.</li>
                <p>6. Privacy Policy</p>
                <li>Our use of personal information is governed by our Privacy Policy, which is incorporated into these Terms and Conditions.</li>
                <p>7. Modifications to the Terms</p>
                <li>We reserve the right to modify these terms at any time. Your continued use of the site after such changes will constitute your acceptance of the new terms.</li>
                <p>9. Contact Information</p>
                <li>For any questions about these Terms and Conditions, please contact us at support@compassistpro.com</li>
                
            </div>
        </section>
    <Footer />
    </body>
    </>
  )
}

export default Terms