import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import './Navbar.css'

const Navbar = () => {
  return (
    <header className="header_wrapper">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img decoding="async" src={Logo} className="img-fluid" alt="logo" />
          </Link>
          {/* <a href="tel:+18008200634" className="main-btnss d-lg-none">Call Us (800)8200634</a> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fas fa-stream navbar-toggler-icon"></i>
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav menu-navbar-nav">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about-us">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">Contact Us</Link>
              </li>
              {/* <li className="nav-item mt-3 mt-lg-0">
                <a className="main-btnss imp" href="tel:+18008200634">Call us for 24X7 assistance</a>
              </li> */}
              {/* Additional links as needed */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;



