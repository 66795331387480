import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import backgroundImage from '../images/slider/slider5.png'

const Contact = () => {

  const bannerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Adjust the height as needed
    backgroundSize: 'cover',
    backgroundPosition: 'center'
};


  return (
    <>
    <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="100">
    <Navbar />

    <section id="home" className="banner_wrapper p-0" style={bannerStyle}>
            {/* <div className="slide-caption text-center">
                <h1>Effective IT Support Services for Your Business</h1>
                <p>Comp Assist Pro is a top-tier IT support services provider that ensures optimal business performance, cybersecurity, and seamless operations.</p>
            </div> */}
        </section>

        <section id="about" class="about_wrapper">
    <div class="container">
        <div class="row flex-lg-row flex-column-reverse">
            <div class="col-lg-6 mb-4 mb-lg-0 ps-lg-4 text-center">
                {/* <!-- Form design here --> */}
                <form class="contact-form">
    <h2 class="form-heading">Fill up the form and we will get back to you</h2>
    <div class="form-group">
        <input type="text" class="form-control" placeholder="Name" required />
    </div>
    <div class="form-group">
        <input type="email" class="form-control" placeholder="Email" required />
    </div>
    <div class="form-group">
        <input type="tel" class="form-control" placeholder="Phone Number" required />
    </div>
    <button type="submit" class="main-btn mt-4">Submit</button>
</form>

            </div>
            <div class="col-lg-6 text-center text-lg-start">
                <h3>Contact Details</h3>
                <p className='fw-bold'>Address 1</p>
                <li>No6401 31ST ST S APT 112 SAINT PETERSBURG, FL. 33712 US</li>
                
                <li>2296 Henderson Mill Rd #116, Atlanta, GA 30345</li>
                <p className='fw-bold'>Email</p>
                <li>support@compassistpro.com</li>
                
                
               
            </div>
        </div>
    </div>
</section>
    <Footer />
    </body>
    </>
  )
}

export default Contact